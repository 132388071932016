.home-wrapper {
  background-color: var(--color-gray-100);
}

.home-wrapper .card {
  border: 0px solid white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.blog-img {
  object-fit: cover;
}
